<template>
          <div class="form-wrapper" style=" margin-top: 50px;" >
            <div class="vertical-center">
              <div class="inner-block">
                <h3>Reset Password request </h3>
                <form @submit.prevent="login" action="">
                  <div v-if="errors" class="errors">
                    <p v-for="(error, field) in errors" :key="field">
                      {{ error[0] }}
                    </p>
                  </div>

                  <div class="form-group">
                    <label>Email address</label>
                    <input type="email" v-model="email" class="form-control form-control-lg" placeholder="Enter your email"><br>
                    <button type="submit" class="form-group btn btn-primary btn-lg col-md-12 form-control">Send Request</button>

<!--                    <router-link to="/reset-password"></router-link>-->

                  </div>
<!--                  <div class="row">-->
<!--                    <div class="form-group">-->
<!--                      <button type="button" @click="sendRequest" class="form-group btn btn-primary btn-lg col-md-12 form-control">Send Request</button>-->
<!--                    </div>-->
<!--                  </div>-->
                </form>
              </div>
            </div>
          </div>
</template>

<script>
import authService from "../services/auth.service";
import {useStore} from "@/store";
import router from "@/router";
import {toRefs, ref, reactive, onMounted} from "vue";
import {MutationType} from "@/store/mutations";

export default {
  name: "login",
  setup(props) {
    const form = reactive({
      email: '',
      username: '',
    })
    const errors = ref("")
    const store = useStore();
    const  sendRequest = () => {
      if (form.email==="" || form.username==="")
        {
          errors.value="Wrong credentials"
          return false;
        }
      else
      authService.sendRequest({
        email: form.email,
        username: form.username,
      })
      router.push({name: 'login'});
    }
    const  setupForm = () => {
          form.email= '';
          form.username= props.user;
    }

    onMounted(()=>{
        setupForm();
    })
    return {store,...toRefs(form), errors, sendRequest};
  },
  props: {
      user: null
  }
}
</script>

<style lang="scss" scoped>

* {
  box-sizing: border-box;
}

body {
  background: #ddecee !important;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
}

body,
html,
.App,
.vue-tempalte,
.vertical-center {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.vertical-center {
  display: flex;
  text-align: left;
  justify-content: center;
  flex-direction: column;
}

.inner-block {
  width: 550px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}

.vertical-center h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

label {
  font-weight: 500;
}

.forgot-password,
.forgot-password a {
  //text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #01818c;
  margin: 0;
}

.forgot-password a {
  color: #01818c;
}

.social-icons {
  text-align: center;
  font-family: "Open Sans";
  font-weight: 300;
  font-size: 1.5em;
  color: #222222;
}

.social-icons ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.social-icons ul li {
  display: inline-block;
  zoom: 1;
  width: 65px;
  vertical-align: middle;
  border: 1px solid #e3e8f9;
  font-size: 15px;
  height: 40px;
  line-height: 40px;
  margin-right: 5px;
  background: #f4f6ff;
}

.social-icons ul li a {
  display: block;
  font-size: 1.4em;
  margin: 0 5px;
  text-decoration: none;
}
.social-icons ul li a i {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.social-icons ul li a:focus i,
.social-icons ul li a:active i {
  transition: none;
  color: #F58B3D;
}


.btn-info, .btn-primary, .btn-success {
  background-color: #01818c ;
  color: white;
}
.btn-warning {
  background-color: #F58B3D;
  color: white;
}
.btn-info:hover, .btn-info:focus,
.btn-success:hover, .btn-success:focus,
.btn-primary:hover, .btn-primary:focus {
  background-color: #01747f;
  background-position: 0 -15px;
  color: white;
}
.btn-info:active, .btn-info.active,
.btn-success:active, .btn-success.active,
.btn-primary:active, .btn-primary.active {
  background-color: #F58B3D;
}

.btn-warning:active, .btn-warning.active {
  background-color: #01818c;
  color: white;
}
.btn-warning:hover, .btn-warning:focus {
  background-color: #f26c0d;
  background-position: 0 -15px;
  color: white;
}
</style>
